
import {Component, Vue} from 'vue-property-decorator';
import {mapGetters} from 'vuex';


import luHeader from '@/layout/header.component.vue';
import {
  deposit_multisig,
  deposit_vote_multisig,
  getMultisigResult,
  getMultisigSTSWAmount,
  set_new_reward,
  withdraw_multisig,
  withdraw_vote_multisig,
} from "@/stacks/etc/multisig.manager";
import {num2decimal, numFormat_} from "@/stacks/manager";
import {
  BASE_STSW_DATA,
  CONTRACT_NAME_STACKSWAP_FARMING2_1,
  CONTRACT_NAME_STACKSWAP_FARMING2_2,
  CONTRACT_NAME_STACKSWAP_FARMING2_5, CONTRACT_NAME_STACKSWAP_FARMING3_DATA, CONTRACT_NAME_STACKSWAP_FARMING3_LOGIC,
  CONTRACT_NAME_STACKSWAP_LBTC_STACKING,
  CONTRACT_NAME_STACKSWAP_STACKING,
  STACKSWAP_ADDRESS,
} from "@/stacks/config";
import {CONTRACT_TYPE, getFarmAmount2, getFarmBalance2} from "@/stacks/farming/farm2.manager";
import BigNumber from "bignumber.js";

@Component({
  components: {
    luHeader,
  },
  computed: {
    ...mapGetters([
      'isConnected',
    ]),
  },
})
export default class MultisigPage extends Vue {


  multisig_deposit_amount: string = '';
  multisig_withdraw_amount: string = '';
  multisig_withdraw_address: string = '';

  multisig_deposit_vote: string = '';
  multisig_withdraw_vote: string = '';

  amount_in_contract: string = 'Loading...';
  deposit_vote_status: string = 'Loading...';
  withdraw_vote_status: string = 'Loading...';
  voters: string = 'Loading...';
  id_list: Array<{name: string, amount_string: string, amount: string, contract: string, contractType: CONTRACT_TYPE}> =
      [
          {name: 'farming v2-1', amount_string: '322,430.976 STSW', amount: '322430976000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_FARMING2_1()}`, contractType: CONTRACT_TYPE.FARM_V2},
          {name: 'farming v2-2', amount_string: '13,434.624 STSW',  amount: '13434624000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_FARMING2_2()}`, contractType: CONTRACT_TYPE.FARM_V2},
          {name: 'farming v2-5', amount_string: '26,869.248 STSW',  amount: '26869248000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_FARMING2_5()}`, contractType: CONTRACT_TYPE.FARM_V2},
          {name: 'STSW staking', amount_string: '1,233,792 STSW',  amount: '1233792000000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_STACKING()}`, contractType: CONTRACT_TYPE.STAKING},
          {name: 'lBTC staking', amount_string: '1,324,270.08 STSW',  amount: '1324270080000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_LBTC_STACKING()}`, contractType: CONTRACT_TYPE.STAKING},
          {name: 'farming v3', amount_string: '3500 STSW',  amount: '1324270080000', contract: `${STACKSWAP_ADDRESS()}.${CONTRACT_NAME_STACKSWAP_FARMING3_DATA()}`, contractType: CONTRACT_TYPE.FARM_V3},
      ];
  contract_remain: string = 'Loading...';
  amount_string: string = 'Loading...';
  selected_item: {name: string, amount_string: string, amount: string, contract: string, contractType: CONTRACT_TYPE} = this.id_list[0];
  amount_contract: string = '';

  new_reward: string = '';

  group_list: Array<String> = ['1', '2', '5'];
  group_to_set: string = '';

  created() {
    if (this.$store.getters.isConnected) {
      this.onConnect();
    }
    this.$store.dispatch('setCallback', this.onConnect);
    getFarmBalance2(this, BASE_STSW_DATA(), this.selected_item.contract.split('.')[1]).then((value) => {
      console.log(value);
      this.contract_remain = numFormat_(num2decimal(value, 6), 6);
      getFarmAmount2(this, this.selected_item.contract, BASE_STSW_DATA(), this.selected_item.contract.split('.')[1], this.selected_item.contractType).then((value2) => {
        this.amount_string = numFormat_(num2decimal(value2, 6), 6);
        this.amount_contract = value2;

        console.log(this.amount_string);
      });
    });
  }

  async onConnect() {
    // console.log();
    this.amount_in_contract = numFormat_(num2decimal(await getMultisigSTSWAmount(this), 6));
    this.deposit_vote_status = await getMultisigResult(this, 'get-res-deposit');
    this.withdraw_vote_status = await getMultisigResult(this, 'get-res-withdraw');
    const temp_voters = await getMultisigResult(this, 'get-voters');
    this.voters = '';
    for (const tempVoter of temp_voters) {
      this.voters += tempVoter.value;
      this.voters += ', ';
    }
  }
  onChangeList(event: any) {
    this.contract_remain = 'Loading...';
    const contract = this.selected_item.contract;
    getFarmBalance2(this, BASE_STSW_DATA(), this.selected_item.contract.split('.')[1]).then((value) => {
      console.log(value);
      this.contract_remain = numFormat_(num2decimal(value, 6), 6);
      if(this.is_farm_V3()) {
        this.amount_string = 'Please Select Group'
        return;
      }
      getFarmAmount2(this, this.selected_item.contract, BASE_STSW_DATA(), this.selected_item.contract.split('.')[1], this.selected_item.contractType).then((value2) => {
        this.amount_string = numFormat_(num2decimal(value2, 6), 6);
        this.amount_contract = value2;
        console.log(this.amount_string);
      });
    });
  }

  onChangeGroup(event: any) {
    const contract = this.selected_item.contract;
      getFarmAmount2(this, this.selected_item.contract, BASE_STSW_DATA(), this.selected_item.contract.split('.')[1], this.selected_item.contractType, this.group_to_set).then((value2) => {
        this.amount_string = numFormat_(num2decimal(value2, 6), 6);
        this.amount_contract = value2;
        console.log('amount2', this.amount_string);
      });
  }
  connectWallet() {
    this.$store.dispatch('connectWallet', this.onConnect);
  }

  deposit_token() {
    deposit_multisig(this, this.multisig_deposit_amount);
  }

  withdraw_token() {
    withdraw_multisig(this, this.multisig_withdraw_address, this.multisig_withdraw_amount);
  }

  withdraw_vote() {
    withdraw_vote_multisig(this, this.multisig_withdraw_vote !== '');
  }

  deposit_vote() {
    deposit_vote_multisig(this, this.multisig_deposit_vote !== '');
  }
  withdraw_to_contract() {
    console.log(this.amount_contract);
      if(this.is_farm_V3()) {
        withdraw_multisig(this, this.selected_item.contract, new BigNumber(this.amount_contract).multipliedBy(8).toFixed(0));
      } else {
        try {
          withdraw_multisig(this, this.selected_item.contract, new BigNumber(this.amount_string.replace(',','')).multipliedBy(10 ** 6).toFixed(0));
        }catch (e) {
          console.log(e);
          withdraw_multisig(this, this.selected_item.contract, this.amount_contract);
        }
      }

  }

  set_new_reward() {
    set_new_reward(this, this.selected_item.contract, new BigNumber(this.new_reward).multipliedBy(10 ** 6).toFixed(0), this.selected_item.contractType, this.group_to_set);
  }
  is_farm_V3() {
    return this.selected_item && this.selected_item.contractType == CONTRACT_TYPE.FARM_V3;
  }

}
